// 'use strict';

// class NavbarController {
//   //start-non-standard
//   menu = [{
//     'title': 'Home',
//     'state': 'main'
//   }];

//   isCollapsed = true;
//   //end-non-standard

//   constructor(Auth) {
//     this.isLoggedIn = Auth.isLoggedIn;
//     this.isAdmin = Auth.isAdmin;
//     this.getCurrentUser = Auth.getCurrentUser;
//   }
// }

// angular.module('kljDigitalLibraryApp')
//   .controller('NavbarController', NavbarController);

angular.module('kljDigitalLibraryApp')
  .controller('NavbarController', function(){
      menu = [{
        'title': 'Home',
        'state': 'main'
      }];
       isCollapsed = true;
        this.isLoggedIn = Auth.isLoggedIn;
    this.isAdmin = Auth.isAdmin;
    this.getCurrentUser = Auth.getCurrentUser;
  })

